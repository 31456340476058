<template>
    <div class="reviews-btn">
        <button
            @click="getNextPage"
            class="button"
        >
            Показать ещё
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: "ReviewsShowMore",

    methods: {
        getNextPage() {
            this.$emit('getNextPage')
        },
    }
})
</script>

<style lang="scss" scoped>
.reviews-btn {
    @apply
        mt-10
        flex
        justify-center;
}
</style>
