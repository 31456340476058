import KeenSliderHelper from '~/classes/keen-slider-helper'
import throttle from '~/helpers/throttle'
// import { fetchRequest } from "~/helpers/fetch"

let mobileSlidersInstances: KeenSliderHelper[] = []

window.addEventListener('DOMContentLoaded', () => {
    const container = document.querySelector('.about-page') as HTMLElement

    if (!container) return void 0

    const containsMap = container.dataset.containsMap as string

    initSlider()
    handleMobileSliders()
    // initListeners()

    if (!containsMap) return void 0

    handleHacks()
    handleResize()
})

const initSlider = () => {
    const container = document.getElementById('about-slider-advantages') as HTMLElement

    if (!container) return void 0

    const slider = new KeenSliderHelper(
        container,
        {
            slides: {
                perView: 1,
                spacing: 0,
            },
            breakpoints: {
                '(min-width: 640px)': {
                    slides: {
                        perView: 2,
                        spacing: 0
                    }
                },
                '(min-width: 960px)': {
                    slides: {
                        perView: 3,
                        spacing: 24
                    }
                },
                '(min-width: 1200px)': {
                    slides: {
                        perView: 3,
                        spacing: 40
                    }
                },
            }
        }
    )

    slider.createAdditionalMarkup({
        arrows: {
            enable: true
        },
        dots: {
            enable: true
        },
        counter: {
            enable: false
        }
    })

    container.classList.remove('opacity-0')
}

const handleResize = () => {
    const throttledHacks         = throttle(handleHacks, 500)
    const throttledMobileSliders = throttle(handleMobileSliders, 500)

    window.addEventListener('resize', () => {
        throttledHacks()
        throttledMobileSliders()
    })
}

const handleMobileSliders = () => {
    const isMobileVP = window.innerWidth < 960 ? true : false

    isMobileVP ? initMobileSliders() : destroyMobileSliders()
}

const destroyMobileSliders = () => {
    if (!mobileSlidersInstances.length) return void 0

    mobileSlidersInstances.forEach((slider: KeenSliderHelper) => {
        slider.destroy()

        if (slider.wrapperElement?.id === 'js-product-list') {
            const child = slider.wrapperElement.firstElementChild as HTMLElement
            if (child) child.classList.remove('keen-slider')
        }
    })

    mobileSlidersInstances = []
}

const initMobileSliders = () => { // тут кал
    if (mobileSlidersInstances.length) return void 0

    let container = document.getElementById('about-slider-cards') as HTMLElement
    let slider = undefined

    if (container) {
        slider = new KeenSliderHelper(
            container,
            {
                slides: {
                    perView: 1,
                    spacing: 0,
                },
            }
        )

        slider.createAdditionalMarkup({
            arrows: {
                enable: false
            },
            dots: {
                enable: true
            },
            counter: {
                enable: false
            }
        })

        container.classList.remove('opacity-0')

        mobileSlidersInstances.push(slider)
    }

    container = document.getElementById('js-product-list') as HTMLElement

    if (container) {
        const child = container.firstElementChild as HTMLElement

        if (child) container = child

        container.classList.add('keen-slider')

        slider = new KeenSliderHelper(
            container,
            {
                slides: {
                    perView: 1,
                    spacing: 0,
                },
                breakpoints: {
                    '(min-width: 640px)': {
                        slides: {
                            perView: 2,
                            spacing: 24
                        }
                    }
                },
            },
        )

        slider.createAdditionalMarkup({
            arrows: {
                enable: false
            },
            dots: {
                enable: true
            },
            counter: {
                enable: false
            }
        })

        container.classList.remove('opacity-0')

        mobileSlidersInstances.push(slider)
    }
}

const handleHacks = () => {
    useVHHAck()
}

const useVHHAck = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}

// const updateCurrentCity = async () => {
//     console.log('11111')
//     try {
//         const { data } = await fetchRequest('/module/cities/ajax?ajax=1', { method: 'GET' })

//         if (data) {
//             const mainTitle = data.attributes.titleCaseDecl
//             const titleElement = document.querySelector('.main-title span')
            
//             if (!titleElement) return
//             console.log(mainTitle, 'mainTitle mainTitle')
//             titleElement.innerHTML = mainTitle
//         }
//     } catch (e) {
//         console.error(e)
//     }
// }


// const initListeners = () => {
//     console.log('222222')
//     const headerCitySelect = document.querySelector('.header__city-select')

//     if (!headerCitySelect) return

//     headerCitySelect.addEventListener('click', () =>  updateCurrentCity())

// }