window.addEventListener('DOMContentLoaded', () => {
    const tabsBtnContainer = document.querySelector('.how-to-make-order__tabs') as HTMLElement
    const tabsBtn = document.querySelectorAll('.how-to-make-order__tabs > button') as NodeListOf<HTMLElement>
    const contents = document.querySelectorAll('.how-to-make-order__steps-content') as NodeListOf<HTMLElement>

    if (!tabsBtnContainer || (!tabsBtn || tabsBtn.length <= 0) || (!contents || contents.length <= 0)) return

    tabsBtnContainer.addEventListener('click', (e: Event) => {
        const target = e.target as HTMLElement
        
        if (target.classList.contains('how-to-make-order__tabs-btn')) {
            resetClasses()

            const dataContent = target.getAttribute('data-content')

            target.classList.add('active')

            contents.forEach(content => {
                if (content.getAttribute('data-content') === dataContent) {
                    content.classList.add('active')
                }
            })
        }
    })

    const resetClasses = () => {
        tabsBtn.forEach(btn => btn.classList.remove('active'))
        contents.forEach(content => content.classList.remove('active'))
    }
})