import { Map, IProjection } from "yandex-maps"
import ymaps from "ymaps"
import { fetchRequest } from "~/helpers/fetch"
import KeenSliderHelper from '~/classes/keen-slider-helper'
import { KeenSliderInstance } from "keen-slider"

const mapContainer = document.querySelector<HTMLElement>("#contacts-map")
let isLoadingNow = false
let mapInstance = {} as Map
let sliderInstance = {} as KeenSliderInstance

const getCityContacts = async (additonalQuery = "") => {
    try {
        isLoadingNow = true
        return await fetchRequest(
            `?ajax=1&controller=contact&action=getCityContacts${additonalQuery}`,
            {}
        )
    } catch (error) {
        console.error(error)
    } finally {
        isLoadingNow = false
    }
}

window.addEventListener("DOMContentLoaded", () => {
    initSlider()
    
    if (!mapContainer) return void 0

    initMap()
})

const initSlider = () => {
    const container = document.getElementById('contacts-slider')
    const slides = container?.querySelectorAll<HTMLElement>('.keen-slider__slide')

    if (!container || !slides || slides.length < 2) {
        if (container) container.classList.remove('opacity-0')

        return void 0
    }
    const slider = new KeenSliderHelper(
        container,
        {
            loop: true,
        }
    )

    slider.createAdditionalMarkup({
        arrows: {
            enable: false,
        },
        dots: {
            enable: true
        },
        counter: {
            enable: false
        }
    })

    container.classList.remove('opacity-0')

    sliderInstance = slider.instance

    initObserver()
}

const initObserver = () => {
    const swipeBlock  = document.querySelector<HTMLElement>(".swipe-block")

    if (!swipeBlock || !sliderInstance) return void 0

    const domObserver = new MutationObserver(() => observerCallback())
    domObserver.observe(swipeBlock, {
        attributes: true
    })
}

const observerCallback = () => {
    if (!sliderInstance) return void 0
    sliderInstance.update()
}

const initMap = () => {
    ymaps
        .load(
            'https://api-maps.yandex.ru/2.1/?apikey=b5f8645b-ddaf-46d9-a8d7-1901caebb464&coordorder=longlat&lang=ru_RU'
        )
        .then((maps) => {
            (async () => {
                try {
                    const response = await getCityContacts()

                    const geoQuery = maps.geoQuery(response)

                    const map = new maps.Map(
                        "contacts-map",
                        {
                            center: response.features[0].geometry.coordinates,
                            zoom: 11,
                            controls: ["zoomControl"],
                        },
                        {
                            suppressMapOpenBlock: true,
                        }
                    )

                    map.behaviors.disable("scrollZoom")
                    geoQuery.addToMap(map)
                    mapInstance = map
                    initListeners()
                } catch (error) {
                    console.error(error)
                }
            })()
        })
        .catch((error) => console.log("Failed to load Yandex Maps", error))
}

const initListeners = () => {
    initMarkerListeners()
}

const initMarkerListeners = () => {
    const showMapButtons = document.querySelectorAll<HTMLElement>('.static-page__button--map')

    if (!showMapButtons.length) return void 0

    showMapButtons.forEach((el: HTMLElement) => {
        el.addEventListener('click', () => {
            const markerId = Number(el.id)
            const marker = mapInstance.geoObjects.get(markerId)
            const coordinates = marker.properties.get("coordinates", {})
            mapInstance.setCenter(Object.values(coordinates), 13)
        })
    })
}
