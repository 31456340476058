<template>
    <div class="gratitude-reviews__item dropdown-entry" data-relation="reviews-slider">
        <img
            v-if="review.gratitude"
            :src="'/download/' + review.gratitude"
            alt="письмо с благодарностью">

        <div class="gratitude-reviews__content">{{ review.customer_name }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import { IReviewsCompany } from '~/interfaces/feedback/feedback'

export default defineComponent({
    name: "GratitudeSliderItem",

    props: {
        review: {
            type: Object as PropType<IReviewsCompany>,
            required: true,
        }
    },
})
</script>

<style lang="scss" scoped>
.gratitude-reviews {
    &__item {
        @apply
            cursor-pointer;

        img {
            @apply
                rounded-2xl
                h-full
                w-full;
        }
    }

    &__content {
        @apply
            mt-3.5
            text-body-small
            text-pale-sky-100
            leading-[171%];
    }
}
</style>
