<template>
    <section class="gratitude-reviews">
        <h2 class="gratitude-reviews__title">благодарности от партнеров<br>«экспресс офис»</h2>
        <div class="keen-slider-wrapper keen-slider-wrapper__gratitude-reviews">
            <div class="keen-slider" ref="slider">
                <GratitudeSliderList
                    :reviewsList="reviewsList"
                    @setCurrentSlide="setCurrentSlide"/>
            </div>
        </div>

        <DropdownScreenGallery relation="reviews-slider" class="drop-gratitude-reviews">
            <div class="keen-slider-wrapper keen-slider-wrapper__drop-gratitude-reviews">
                <div class="keen-slider" ref="dropdownSlider">
                    <GratitudeDropSlderList
                        :reviewsList="reviewsList"/>
                </div>
            </div>
        </DropdownScreenGallery>
    </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import KeenSliderHelper             from '~/classes/keen-slider-helper'
import { getSvgSpriteIcon }     from "~/helpers/get-svg-sprite-icon"

import { IReviewsCompany } from '~/interfaces/feedback/feedback'

import DropdownScreenGallery        from "~vue/dropdown-wrappers/DropdownScreenGallery.vue"
import GratitudeSliderList from "~vue/feedback/gratitude-reviews/slider/GratitudeSliderList.vue"
import GratitudeDropSlderList from "~vue/feedback/gratitude-reviews/dropdown-slider/GratitudeDropSlderList.vue"

export default defineComponent({
    name: "GratitudeReviews",
    components: {
        DropdownScreenGallery,
        GratitudeSliderList,
        GratitudeDropSlderList
    },

    props: {
        reviewsList: {
            type: Object as PropType<IReviewsCompany[]>,
            required: true,
        }
    },

    data() {
        return {
            items: [1, 2, 3],
            currentSlide: 0,
            slider: {} as KeenSliderHelper | null,
            dropdownSlider: {} as KeenSliderHelper | null,
        }
    },

    mounted() {
        this.initSlider(this.$refs.slider as HTMLElement, this.reviewsList.length)
        this.initDropdownSlider(this.$refs.dropdownSlider as HTMLElement, this.reviewsList.length)
    },

    computed: {
        galleryArrowIcon() {
            return getSvgSpriteIcon('icon-arrow-gallery')
        },
    },

    methods: {
        initSlider(container: HTMLElement, elemLength: number): void {
            if (elemLength < 3) {
                return
            }

            this.slider = new KeenSliderHelper(
                container,
                {
                    initial: 0,
                    loop: false,
                    breakpoints: {
                        '(min-width: 320px)': {
                            slides: {
                                perView: 2,
                                spacing: 27
                            },
                        },
                        '(min-width: 640px)': {
                            slides: {
                                perView: 3,
                                spacing: 27
                            },
                        },
                    },
                    slides: {
                        perView: 3,
                        spacing: 27
                    },
                    disabled: false,
                },
            )

            this.slider?.createAdditionalMarkup({
                dots: {
                    enable: true
                }
            })
        },

        initDropdownSlider(container: HTMLElement, elemLength: number): void {
            if (elemLength < 2) {
                return
            }

            this.dropdownSlider = new KeenSliderHelper(
                container,
                {
                    initial: 0,
                    loop: false,
                    slides: {
                        perView: 1,
                    },
                    disabled: false,
                },
            )

            this.dropdownSlider?.createAdditionalMarkup({
                arrows: {
                    enable: true,
                    html: `<svg><use href="${this.galleryArrowIcon}"></use></svg>`
                }
            })
        },

        setCurrentSlide(key: number) {
            if (this.reviewsList.length < 2) {
                return
            }

            this.currentSlide = key
            this.dropdownSlider?.instance.moveToIdx(this.currentSlide, false, {
                duration: 0,
            })
        }
    }
})
</script>

<style lang="scss">
.keen-slider-wrapper__gratitude-reviews.keen-slider-wrapper {
    .keen-slider-wrapper__dots-list {
        @apply
            bottom-[-1.5rem];
    }
}

.keen-slider-wrapper__drop-gratitude-reviews.keen-slider-wrapper {
    @apply
        h-full
        max-h-full

        sm:mt-40

        md:max-w-[63vw]
        md:my-0
        md:mx-auto
        md:h-screen
        md:mt-0
        md:max-h-[70vh]

        xlg:max-h-[85vh]
        #{!important};
}

.dropdown-screen.dropdown-screen--gallery.drop-gratitude-reviews {
    .dropdown-screen__outer {
        @apply transition-none;
    }
}

.dropdown-screen.dropdown-screen--gallery.drop-gratitude-reviews.dropdown-screen--opened {
    .dropdown-screen__outer {
        @apply transition duration-500 ease-in-out;
    }
}
</style>

<style lang="scss" scoped>
.keen-slider-wrapper {
    @apply
        flex
        flex-col
        w-full
        h-full

        md:flex-row;

    &__gratitude-reviews {
        @apply
            mt-10;
    }
}

.gratitude-reviews {
    @apply
        mt-20;

    &__title {
        @apply
            text-h2
            uppercase
            font-medium
            text-center
            leading-[114%]
            tracking-[0.07em];
    }
}
</style>
