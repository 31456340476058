<template>
    <div class="gratitude-reviews__item">
        <img
            v-if="review.gratitude"
            :src="'/download/' + review.gratitude"
            alt="письмо с благодарностью">
        <div class="gratitude-reviews__content-block">
            <div class="item__name">{{ review.customer_name }}</div>
            <div class="item__content">
                <p
                    v-for="(content, key) in contentList"
                    :key="key">
                        {{ content }}
                </p>
            </div>
            <div class="item__rating">
                <div class="item__date">{{ dateForSemanticTag }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import { IReviewsCompany } from '~/interfaces/feedback/feedback'

export default defineComponent({
    name: "GratitudeDropSliderItem",

    props: {
        review: {
            type: Object as PropType<IReviewsCompany>,
            required: true,
        }
    },

    data() {
        return {
            contentList: [] as string[]
        }
    },

    mounted() {
        this.reformatContent()
    },

    computed: {
        dateForSemanticTag(): string {
            let date = this.review.date_add

            const resultDate = date.split(' ')

            return resultDate[0].split('-').reverse().join('.')
        },
    },

    methods: {
        reformatContent(): void {
            this.contentList = this.review.content.split('\n')
        }
    },
})
</script>

<style lang="scss" scoped>
.gratitude-reviews {
    &__item {
        @apply
            h-full
            flex
            flex-col
            items-center
            max-w-[1053px]
            p-4

            sm:pt-[1.188rem]
            sm:pl-0
            sm:pb-4
            sm:pr-0

            md:pb-0
            md:flex-row
            md:items-start;

        img {
            @apply
                w-full
                max-w-[33.125rem]
                h-[50vh]
                object-contain

                xsm:h-[75vh]
                xsm:max-w-[24.625rem]

                sm:w-[28rem]
                sm:mb-auto
                sm:rounded-l-2xl
                sm:h-full

                md:max-w-[33.125rem]

                lg:w-full;
        }
    }

    &__content-block {
        @apply
            pt-[3.25rem]

            sm:pl-0

            md:pl-11;
    }
}

.item {
    &__name {
        @apply
            text-overline
            leading-[133%]
            font-medium;
    }

    &__content {
        @apply
            text-body
            leading-[150%]
            text-shuttle-gray
            font-normal;

        p:first-child {
        @apply
            mt-[22px];
        }

        p {
            @apply
                mt-[8px];
        }
    }

    &__rating {
        @apply
            flex
            mt-4;
    }

    &__date {
        @apply
            text-body-small
            leading-[171%]
            font-normal
            text-pale-sky-70
            mr-[50px];
    }
}
</style>
