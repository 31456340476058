import { createApp }                    from 'vue'
import { createBem }                    from '@vuebits/bem'
import Maska                            from 'maska'
import TheCartFullAssemblyTerms         from '~vue/cart/full/TheCartFullAssemblyTerms.vue'
import TheCartFullLiftingTerms          from '~vue/cart/full/TheCartFullLiftingTerms.vue'
import FormsHandlerBulk                 from '~vue/forms/FormsHandlerBulk.vue'
import TheCartDeliveryWithMapWrapper    from '~vue/cart/delivery/TheCartDeliveryWithMapWrapper.vue'
import TheBotChecker                    from '~vue/maintenance/TheBotChecker.vue'
import SearchForm                       from '../search/SearchForm.vue'


// TODO: все компоненты для статических страниц вынести по пример FormsHandlerBulk или написать буквально один компонент, как для форм, так и для остального

createApp(TheCartFullAssemblyTerms)
    .use(createBem({
        hyphenate: true
    }))
    .use(Maska)
    .mount('#assembly-mount')

createApp(TheCartFullLiftingTerms)
    .use(createBem({
        hyphenate: true
    }))
    .use(Maska)
    .mount('#lifting-mount')

createApp(TheCartDeliveryWithMapWrapper)
    .use(createBem({
        hyphenate: true
    }))
    .use(Maska)
    .mount('#delivery-mount')

createApp(SearchForm)
    .use(createBem({
        hyphenate: true
    }))
    .use(Maska)
    .mount('#error-404-page-search')

// About page mounts
createApp(FormsHandlerBulk, {
    componentsList: [
        {
            component: 'FormsTwoColumnsFormAsync',
            mountSelector: '#about-page-design-request-inline',
            relation: ''
        },
        {
            component: 'FormsCallbackFormAsync',
            mountSelector: '#about-page-partnership-modal',
            relation: 'about-page-partnership'
        },
        {
            component: 'FormsTwoColumnsFormAsync',
            mountSelector: '#about-page-questions-inline',
            relation: ''
        },
        {
            component: 'FormsTwoColumnsFormAsync',
            mountSelector: '#about-page-design-request-modal',
            relation: 'about-page-design-request-modal'
        },
    ]
})
    .use(createBem({
        hyphenate: true
    }))
    .use(Maska)
    .mount('#about-page-forms-mount')
