<template>
    <div
        v-for="(review, index) in reviewsList"
        :key="index"
        class="keen-slider__slide gratitude-reviews__list"
    >
        <GratitudeDropSliderItem
            :review="review"/>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import { IReviewsCompany } from '~/interfaces/feedback/feedback'

import GratitudeDropSliderItem from "~vue/feedback/gratitude-reviews/dropdown-slider/GratitudeDropSliderItem.vue"

export default defineComponent({
    name: "GratitudeDropSlderList",
    components: {
        GratitudeDropSliderItem,
    },

    props: {
        reviewsList: {
            type: Object as PropType<IReviewsCompany[]>,
            required: true,
        }
    },
})
</script>

<style lang="scss">
.drop-gratitude-reviews {
    .dropdown-screen__close {
        @apply
            top-[10rem];
    }
}
</style>
