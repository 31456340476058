
import throttle from '~/helpers/throttle'
import {scrollTo} from '~/helpers/scroll'

window.addEventListener('DOMContentLoaded', () => {
    const container = document.querySelector('.static-page') as HTMLElement

    if (!container) return void 0

    handleHacks()
    handleResize()
    initListeners()
    initTagsClick()
    initStaticTabByHash()
})

const initTagsClick = () => {
    const tags = document.querySelectorAll('.static-page__tag') as NodeListOf<HTMLElement>

    if (tags) tags.forEach((tag: HTMLElement) => tag.addEventListener('click', toggleBlock))
}

const initStaticTabByHash = () => {
    const url = new URL(location.href)

    if (url.hash) {
        const [name, value] = url.hash.split(':')
        const tags = document.querySelectorAll('.static-page__tag') as NodeListOf<HTMLElement>

        if (name === '#tab' && value && tags) {
            const target = Array.from(tags).find(({ dataset }) => dataset.target === value)
            target?.dispatchEvent(new Event('click'))
        }
    }
}

const toggleBlock = () => {
    const element = event?.target as HTMLElement

    const tags = document.querySelectorAll('.static-page__tag') as NodeListOf<HTMLElement>

    if (!tags.length) return void 0

    tags.forEach((tag: HTMLElement) => tag.dataset.target == element.dataset.target
        ? tag.classList.add('static-page__tag--selected')
        : tag.classList.remove('static-page__tag--selected')
    )

    const blocks = document.querySelectorAll('.static-page__text-wrapper[data-type]') as NodeListOf<HTMLElement>

    if (!blocks.length) return void 0

    blocks.forEach((block: HTMLElement) => block.dataset.type == element.dataset.target
        ? block.classList.remove('static-page__text-wrapper--hidden')
        : block.classList.add('static-page__text-wrapper--hidden')
    )
}

const initListeners = () => {
    handleMapButtonClick()
    handleMapClose()
}

const handleMapButtonClick = () => {
    const mapButtonsContainers = document.querySelectorAll<HTMLButtonElement>('.static-page__button--map')
    const mapContainer = document.querySelectorAll<HTMLElement>('.static-page__map-container')

    if (!mapButtonsContainers.length || !mapContainer) return void 0

    mapButtonsContainers.forEach((el: HTMLButtonElement) => {
        el.addEventListener('click', () => {
            toggleMapContainerVisibility(true)
            scrollTo('.static-page__map-container', 64)
        })
    })
}

const toggleMapContainerVisibility = (forcedValue: boolean) => {
    const rightContentContainer = document.querySelector('.static-page__right-content')

    if (!rightContentContainer) return void 0

    rightContentContainer.classList.toggle('static-page__right-content--toggled', forcedValue)
}

const handleMapClose = () => {
    const mapCloseButton = document.querySelector('.static-page__map-close')

    if (!mapCloseButton) return void 0

    mapCloseButton.addEventListener('click', () => {
        toggleMapContainerVisibility(false)
    })
}


const handleResize = () => {
    const throttledHacks = throttle(handleHacks, 500)

    window.addEventListener('resize', () => {
        throttledHacks()
    })
}

const handleHacks = () => {
    useVHHAck()
}

const useVHHAck = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}
