<template>
    <div
        v-for="(review, index) in reviewsList"
        :key="index"
        class="keen-slider__slide gratitude-reviews__list"
        @click="setCurrentSlide(index)">

        <GratitudeSliderItem :review="review"/>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import { IReviewsCompany } from '~/interfaces/feedback/feedback'

import GratitudeSliderItem from '~vue/feedback/gratitude-reviews/slider/GratitudeSliderItem.vue'

export default defineComponent({
    name: "GratitudeSliderList",
    components: {
        GratitudeSliderItem,
    },

    props: {
        reviewsList: {
            type: Object as PropType<IReviewsCompany[]>,
            required: true,
        }
    },

    methods: {
        setCurrentSlide(key: number) {
            this.$emit('setCurrentSlide', key)
        }
    }
})
</script>

<style lang="scss" scoped>
.th-reviews {
    &__list {
        @apply
            mt-10;
    }
}
</style>
