<template>
    <section class="reviews">
        <h2 class="reviews__title">Мнения наших покупателей</h2>
        <TotalReviews
            :commentsCount="companyReviewsCount"
            :totalGrade="totalGrade"
            :gradeList="gradeList"/>
        <ReviewsList
            v-if="reviewsCompanyPaginate.length > 0"
            :reviewsList="reviewsCompanyPaginate "/>
        <ReviewsShowMore
            v-if="!endOfPage"
            @getNextPage="getNextPage"/>
        <GratitudeReviews
            v-if="reviewsGratitudeList.length > 0"
            :reviewsList="reviewsGratitudeList"/>
    </section>
</template>

<script lang="ts">
import { defineComponent } from "vue"

import { fetchRequest } from "~/helpers/fetch"

import { IGrade, IReviewsCompany, IReviewsFetch } from '~/interfaces/feedback/feedback'

import TotalReviews from "~vue/feedback/reviews/TotalReviews.vue"
import ReviewsList from "~vue/feedback/reviews/ReviewsList.vue"
import ReviewsShowMore from "~vue/feedback/reviews/ReviewsShowMore.vue"
import GratitudeReviews from "~vue/feedback/gratitude-reviews/GratitudeReviews.vue"

export default defineComponent({
    name: "FeedBack",

    components: {
        TotalReviews,
        ReviewsList,
        ReviewsShowMore,
        GratitudeReviews,
    },

    data() {
        return {
            url: '/module/productcomments/ListComments',
            reviewsCompany: [] as IReviewsCompany[] | [],
            reviewsCompanyPaginate: [] as IReviewsCompany[],
            reviewsGratitudeList: [] as IReviewsCompany[] | [],
            totalGrade: 0,
            gradeList: [] as IGrade[],
            companyReviewsCount: 0,
            currentPage: 1,
            countElOnPage: 3,
            endOfPage: false,
        }
    },

    mounted() {
        this.getReviews('2')
        this.getReviews('3')

        if (this.$attrs.container) {
            this.countElOnPage = +this.getContainerProp('countElOnPage') > 0 ? +this.getContainerProp('countElOnPage') : this.countElOnPage
        }
    },

    methods: {
        async getReviews(type: '2' | '3', action = 'reviewsSystem') {
            try {
                const response: IReviewsFetch = await fetchRequest(
                    `${this.url}?action=${action}&type=${type}`,
                    {}
                )

                if (response?.errors?.status) {
                    return
                }

                this.parseJsonMainData(type, response.data)

            } catch (error) {
                console.error(error)
                return
            }
        },

        parseJsonMainData(type: '2' | '3', data: IReviewsCompany[]): void {
            if (type === '2') {
                this.reviewsCompany = data

                this.companyReviewsCount = this.reviewsCompany.length

                // пагинация
                this.paginate(this.currentPage)

                // считаем общее количество отзывов и общую оценку(только для отзывов о компании)
                this.setGradesInfo(this.reviewsCompany, this.companyReviewsCount)
            } else {
                this.reviewsGratitudeList = data
            }
        },

        getContainerProp(propsName: string): string {
            const attrs = this.$attrs.container as HTMLElement | null
            let props = attrs?.getAttribute(propsName) ?? null

            if (!attrs || !props) return ''

            return props
        },

        paginate(page: number): void {
            this.reviewsCompanyPaginate = []
            let countElements = page * this.countElOnPage

            if (countElements >= this.reviewsCompany.length) {
                countElements = this.reviewsCompany.length
                this.endOfPage = true
            }

            for (let i = 0; i < countElements; i++) {
                this.reviewsCompanyPaginate.push(this.reviewsCompany[i])
            }
        },

        setGradesInfo(reviewsCompany: IReviewsCompany[], reviewsCount: number): void {
            let totalGrades = 0
            const grades: number[] = []

            reviewsCompany.forEach((review: IReviewsCompany) => {
                totalGrades += parseInt(review?.grade ?? '')
                grades.push(parseInt(review?.grade ?? ''))
            })

            this.totalGrade = Math.round((totalGrades / reviewsCount) * 10) / 10
            this.gradeList = this.getGradesList(grades)
        },

        getGradesList(grades: number[]): IGrade[] {
            let result = [
                {
                    count: 0,
                    value: 5,
                    width: 0,
                },
                {
                    count: 0,
                    value: 4,
                    width: 0,
                },
                {
                    count: 0,
                    value: 3,
                    width: 0,
                },
                {
                    count: 0,
                    value: 2,
                    width: 0,
                },
                {
                    count: 0,
                    value: 1,
                    width: 0,
                },
            ]

            grades.forEach(grade => {
                result[Math.abs(grade - 5)].count++
            })

            const total = result.reduce((acc, obj) => acc + obj.count, 0)

            result.forEach(obj => {
                obj.width = Math.round((obj.count / total) * 100)
            })

            return result
        },

        getNextPage() {
            this.currentPage++
            this.paginate(this.currentPage)
        },
    },
})
</script>

<style lang="scss" scoped>
.reviews {
    @apply
        pt-20;

    &__title {
        @apply
            text-h2
            uppercase
            font-medium
            text-center
            leading-[114%]
            tracking-[0.07em];
    }
}
</style>
