<template>
    <section class="reviews-list__item">
        <div class="reviews-list__header">
            <div class="reviews-list__title">
                {{ review.customer_name }}
            </div>
            <time class="reviews-list__date">
                {{ dateForSemanticTag }}
            </time>
        </div>

        <div class="reviews-list__rating">
            <StarRating
                :rating="+review.grade"
            />
            <a
                v-if="review.link && review.image"
                :href="review.link"
                target="_blank">
                    <img :src="getIcon(review.image)" alt="иконка отзовика" class="reviews-list__review-icon"/>
            </a>
            <img v-if="!review.link && review.image" :src="getIcon(review.image)" alt="иконка отзовика" class="reviews-list__review-icon"/>
        </div>
        <p class="reviews-list__content">{{ review.content }}</p>
        <hr>
    </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import StarRating from "~vue/catalog/StarRating.vue"

import { IReviewsCompany } from '~/interfaces/feedback/feedback'

export default defineComponent({
    name: "ReviewsItem",
    components: {
        StarRating,
    },

    props: {
        review: {
            type: Object as PropType<IReviewsCompany>,
            required: true
        },
    },

    computed: {
        dateForSemanticTag(): string {
            let date = this.review.date_add

            const resultDate = date.split(' ')

            return resultDate[0].split('-').reverse().join('.')
        },
    },

    methods: {
        getIcon(image: string | null ): string {
            return `/assets/images/reviews/${image}`
        }
    },
})
</script>

<style lang="scss" scoped>
.reviews-list {
    &__item {
        @apply
            mt-6
            first:mt-0
            text-shuttle-gray
            text-body;
    }

    &__header {
        @apply
            flex
            flex-nowrap
            justify-between
            items-baseline
            mb-2;
    }

    &__title {
        @apply
            text-body
            font-medium
            text-black;
    }

    &__date {
        @apply
            text-body-small
            text-pale-sky-70;
    }

    &__rating {
        @apply
            mb-2
            flex
            justify-between;
    }

    &__review-icon {
        @apply
            max-h-6
            h-full;
    }

    &__content {
        @apply
            mb-4
            break-words;
    }

    hr {
        @apply
            h-px
            mt-6
            bg-pale-sky-70
            border-0
            dark:bg-pale-sky-70;
    }

    &__footer {
        @apply
            flex
            flex-wrap
            items-center
            text-pale-sky-70
            text-body-small
            justify-end;
    }

    &__usefull-block {
        @apply
            flex
            items-center
            gap-6
            ml-6;
    }

    &__btn-up {
        @apply
            cursor-pointer
            transition-colors
            duration-200;

        svg {
            @apply
                inline-block
                w-6
                h-6
                stroke-pale-sky-30
                group-hover:stroke-green;
        }
    }

    &__btn-down {
        @apply
            cursor-pointer
            transition-colors
            duration-200;

        svg {
            @apply
                inline-block
                rotate-180
                w-6
                h-6
                stroke-pale-sky-30
                group-hover:stroke-roman;
        }
    }
}
</style>
