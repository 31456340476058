export interface IGrade {
    count: number,
    value: number,
    width: number,
}

export interface IReviewsCompany {
    id_product_comment: string,
    id_product: string | null,
    id_customer: string | null,
    id_guest: string | null,
    title: string,
    content: string,
    customer_name: string | null,
    grade: string,
    validate: string | null,
    deleted: string | null,
    date_add: string,
    date_buy: string | null,
    id_shop: string | null,
    public: string | null,
    is_comment: string | null,
    pros: string | null,
    cons: string | null,
    order_id: string | null,
    phone_number: string | null,
    is_question: string | null,
    is_home24: string | null,
    type: string,
    id_review_system: string,
    link_system: string | null,
    gratitude: string | null,
    link: string | null,
    name: string | null,
    image: string | null,
}

export enum ReviewSystemsEnum {
    'YMarket' = 1,
    'YMap',
    '2Gis',
    'Yell',
    'Flamp',
    'Zoon',
}

export interface IReviewsFetch {
    data: IReviewsCompany[],
    errors: {
        code: string,
        detail: string,
        status: string,
        title: string,
    }
}

export function isReviews(reviews: unknown): reviews is IReviewsCompany[] {
    return (reviews as IReviewsCompany[]).map(item => item.id_product_comment) !== undefined
}
