import KeenSliderHelper from '~/classes/keen-slider-helper'
import { googleDatalayerPersonalRecommendationsClick } from '~/helpers/google-datalayer'

const copyPromo = (e: Event): void => {
    const target = e.target as HTMLElement
    const promoLabel = document.querySelector('.error-404-page__promo-label') as HTMLElement

    target.removeEventListener('click', copyPromo)

    const copyText = target.innerText
    navigator.clipboard.writeText(copyText)
        .then(() => {
            if (!promoLabel) return

            promoLabel.innerText = 'Промокод скопирован'
            promoLabel.style.opacity = '100'
        })
        .finally(() => {
            const timeOut = setTimeout(() => {
                promoLabel.style.opacity = '0'
                target.classList.add('disabled')
                clearTimeout(timeOut)
            }, 2000)
        })
}

window.addEventListener('DOMContentLoaded', () => {
    initRecommendationsProductsNotFound()
    const promoCode = document.querySelector('.error-404-page__promo') as HTMLElement

    if (!promoCode) return

    promoCode.addEventListener('click', copyPromo)
})

const initRecommendationsProductsNotFound = () => {
    const container = document.getElementById('slider-not-found-recommendations-products') as HTMLElement
    console.log(container)
    if (!container) return void 0

    const slider = new KeenSliderHelper(
        container,
        {
            slides: {
                perView: 1,
                spacing: 0,
            },
            breakpoints: {
                '(min-width: 640px)': {
                    slides: {
                        perView: 2,
                        spacing: 24
                    }
                },
                '(min-width: 960px)': {
                    slides: {
                        perView: 4,
                        spacing: 24
                    }
                },
                '(min-width: 1200px)': {
                    slides: {
                        perView: 5,
                        spacing: 0
                    }
                },
                '(min-width: 1440px)': {
                    slides: {
                        perView: 6,
                        spacing: 24
                    }
                },
            }
        }
    )

    slider.createAdditionalMarkup({
        arrows: {
            enable: false,
        },
        dots: {
            enable: true
        },
        counter: {
            enable: false
        }
    })

    container.classList.remove('opacity-0')
    container.addEventListener('click', googleDatalayerPersonalRecommendationsClick)
}
