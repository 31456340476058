<template>
    <div
        class="reviews-list"
    >
        <ReviewsItem
            v-for="review in reviewsListFiltered"
            :key="review.id_product_comment"
            :review="review"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import ReviewsItem from "~vue/feedback/reviews/ReviewsItem.vue"

import { IReviewsCompany, ReviewSystemsEnum } from '~/interfaces/feedback/feedback'

export default defineComponent({
    name: "ReviewsList",
    components: {
        ReviewsItem,
    },
    props: {
        reviewsList: {
            type: Object as PropType<IReviewsCompany[]>,
            required: true,
        }
    },
    computed: {
        reviewsListFiltered() {
            return this.reviewsList.filter(
                review => +review.id_review_system !== ReviewSystemsEnum.YMarket
            )
        }
    }
})
</script>

<style lang="scss" scoped>
.reviews-list {
    @apply
        pt-20
        w-full;
}
</style>
