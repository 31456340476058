<template>
    <section>
        <div class="total-reviews">
            <div class="total-reviews__total">
                {{ totalGrade }}
            </div>
            <div class="total-reviews__list-wrap">
                <div class="total-reviews__total-rating">
                    <span>
                        {{ commentsPlural }}
                    </span>
                    <span>
                        {{ gradesPlural }}
                    </span>
                </div>
                <div class="total-reviews__rating-wrap">
                    <div
                        v-for="(grade, gradeId) in gradeList"
                        :key="gradeId"
                        class="rating__item"
                    >
                        <div>
                            <StarRating :rating="grade.value"/>
                        </div>
                        <div class="rating__delimiter">
                            <div
                                :style="{
                                    width: grade.width + '%'
                                }">
                            </div>
                        </div>
                        <div class="rating__item--value">{{ grade.count }}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

import pluralize from "~/helpers/pluralize"
import StarRating from "~vue/catalog/StarRating.vue"

import { IGrade } from '~/interfaces/feedback/feedback'

export default defineComponent({
    name: "TotalReviews",
    components: {
        StarRating
    },
    props: {
        commentsCount: {
            type: Number,
            default: 0
        },

        gradeList: {
            type: [] as PropType<IGrade[]>,
            required: true
        },

        totalGrade: {
            type: Number,
            default: 0
        }
    },

    computed: {
        commentsPlural():string {
            return pluralize(this.commentsCount, ['отзыв', 'отзыва', 'отзывов'])
        },
        gradesPlural():string {
            return pluralize(this.commentsCount, ['оценка', 'оценки', 'оценок'])
        }
    }
})
</script>

<style lang="scss" scoped>

$className: "reviews-about-home24";
.total-reviews {
    @apply
        grid
        grid-cols-12
        gap-4
        pt-10

        xsm:gap-10
        xsm:grid-cols-4;

    &__total {
        @apply
            col-span-1
            text-right
            text-[4rem]
            leading-[4rem]
            text-orange
            font-medium
            my-auto

            xsm:m-0
            xsm:text-[4.5rem]
            xsm:leading-[4.5rem];
    }

    &__list-wrap {
        @apply
        col-span-8
        col-start-5

        xsm:col-span-3
        xsm:col-start-2;
    }

    &__total-rating {
        @apply
            flex
            justify-between
            text-shuttle-gray
    }

    &__rating-wrap {
        @apply
            mt-6;
    }
}

.rating {
    &__item {
        @apply
            flex
            items-center;

        &--value {
            @apply
                ml-2;
        }
    }

    &__delimiter {
        @apply
            ml-2
            w-full
            bg-pale-sky-10
            h-[2px]
            rounded-[2px]
            overflow-hidden;

        div {
            @apply
                bg-orange
                h-full;
        }
    }
}

.add-review {
    @apply
        col-span-12
        text-center

        xsm:col-span-3
        xsm:col-start-2;

    p {
        @apply
            text-overline
            text-black
            font-medium;
    }
}

.#{$className} {
    .total-reviews {
        @apply xsm:grid-cols-5;
    }
}
</style>
